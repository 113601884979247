import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/es/layout"
//import Image from "../components/image"
import SEO from "../../components/es/seo"

import MainImg from "./../../images/main_bg.jpg"

import shape1 from "./../../images/dec/1.png"
import shape2 from "./../../images/dec/2.png"
import shape3 from "./../../images/dec/3.png"
import shape4 from "./../../images/dec/4.png"
import shape5 from "./../../images/dec/5.png"
import shape6 from "./../../images/dec/6.png"
import shape7 from "./../../images/dec/7.png"
import shape8 from "./../../images/dec/8.png"

import product1 from "./../../images/es/moonamar-aceite-de-comino-negro-organico-250-ml-bio.png"
import product2 from "./../../images/es/moonamar-aceite-de-comino-negro-en-capsulas-halal.png"
import product3 from "./../../images/es/moonamar-champu-con-aceite-de-argan-y-comino-negro.png"
import product4 from "./../../images/es/moonamar-balsamo-para-piel-con-argan-y-comino-negro.png"
import product5 from "./../../images/es/moonamar-jabon-de-manteca-de-karite-y-comino-negro.png"
import product6 from "./../../images/es/moonamar-caramelos-con-comino-negro-curcuma-y-menta.png"

const Produkte = () => (
  <Layout>
    <SEO 
    title="MOONAMAR - SUPLEMENTOS ALIMENTARIOS Y COSMÉTICOS"
    description="Los productos MOONAMAR son el resultado de un trabajo dedicado a recetas naturales con efectos beneficiosos."
    />

    <section
      id="_heroPage"
      className="hero is-large _o-nama"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">PRODUCTOS</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div id="produkte_block" className="columns is-hidden-touch">
              <div className="column is-2 is-narrow">
                  <Link to="/es/producto/aceite-de-comino-negro-ecologico">
                    <figure className="image">
                      <img src={product1} alt="ACEITE DE COMINO NEGRO ORGÁNICO 250 ML (BIO)" title="ACEITE DE COMINO NEGRO ORGÁNICO 250 ML (BIO)" />
                    </figure>
                  </Link>
              </div>
              <div className="column is-2 is-narrow">
                  <Link to="/es/producto/aceite-de-comino-negro-en-capsulas">
                    <figure className="image">
                      <img src={product2} alt="ACEITE DE COMINO NEGRO EN CÁPSULAS 'HALAL'" title="ACEITE DE COMINO NEGRO EN CÁPSULAS 'HALAL'" />
                    </figure>
                  </Link>
              </div>
              <div className="column is-2 is-narrow">
                  <Link to="/es/producto/champu-de-argan-y-comino-negro">
                    <figure className="image">
                      <img src={product3} alt="CHAMPÚ CON ACEITE DE ARGÁN Y COMINO NEGRO" title="CHAMPÚ CON ACEITE DE ARGÁN Y COMINO NEGRO" />
                    </figure>
                  </Link>
              </div>
              <div className="column is-2 is-narrow">
                  <Link to="/es/producto/balsamo-para-la-piel-con-comino-negro-argan-jojoba-aguacate-y-manteca-de-karite">
                    <figure className="image">
                      <img src={product4} alt="BÁLSAMO PARA PIEL CON ARGÁN Y COMINO NEGRO" title="BÁLSAMO PARA PIEL CON ARGÁN Y COMINO NEGRO" />
                    </figure>
                  </Link>
              </div>
              <div className="column is-2 is-narrow">
                  <Link to="/es/producto/jabon-de-manteca-de-karite-con-aceite-de-comino">
                    <figure className="image">
                      <img src={product5} alt="JABÓN DE MANTECA DE KARITÉ Y COMINO NEGRO" title="JABÓN DE MANTECA DE KARITÉ Y COMINO NEGRO" />
                    </figure>
                  </Link>
              </div>
              <div className="column is-2 is-narrow">
                  <Link to="/es/producto/caramelos-de-comino-negro-curcuma-menta-y-limon">
                    <figure className="image">
                      <img src={product6} alt="CARAMELOS CON COMINO NEGRO, CÚRCUMA Y MENTA" title="CARAMELOS CON COMINO NEGRO, CÚRCUMA Y MENTA" />
                    </figure>
                  </Link>
              </div>
            </div>
          </div>
        </div>
        <div id="produkte_list" className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns __prod">
              <div className="column is-3">
                <nav className="_sidebar">
                      <Link to="/es/productos/#1">Aceite de comino negro ecológico</Link>
                      <Link to="/es/productos/#2">Aceite de comino negro en cápsulas</Link>
                      <Link to="/es/productos/#3">Champú de argán y comino negro</Link>
                      <Link to="/es/productos/#4">Bálsamo para la piel con comino negro, argán, jojoba, aguacate y manteca de karité</Link>
                      <Link to="/es/productos/#5">Jabón de manteca de karité con aceite de comino</Link>
                      <Link to="/es/productos/#6">Caramelos de comino negro, curcuma, menta y limón</Link>
                  </nav>
              </div>
              <div className="column is-9">
                <h3 className="title">SELECCIÓN EXCLUSIVA DE PRODUCTOS MOONAMAR</h3>
                <p className="content">Los productos MOONAMAR son el resultado del respeto. Efectos beneficiosos para su cuerpo. El jabón de karité Moonamar</p>
                <div id="1" className="columns __prod">
                    <div className="column is-4">
                        <figure className="image is-desktop">
                            <img src={product1} alt="ACEITE DE COMINO NEGRO ORGÁNICO 250 ML (BIO)" title="ACEITE DE COMINO NEGRO ORGÁNICO 250 ML (BIO)"/>
                        </figure>
                    </div>
                    <div className="column">
                        <p className="title is-4">Aceite de comino negro ecológico</p>
                        <figure className="image is-mobile">
                            <img src={product1} alt="ACEITE DE COMINO NEGRO ORGÁNICO 250 ML (BIO)" title="ACEITE DE COMINO NEGRO ORGÁNICO 250 ML (BIO)"/>
                        </figure>
                        <p className="content">El aceite de comino negro de Moonamar se consigue de un primer prensado y sin otros tratamientos para así poder cuidar todas las propiedades beneficiosas para nuestro organismo que contiene.</p>
                        <Link
                          to="/es/producto/aceite-de-comino-negro-ecologico"
                          className="button is-text is-small btn2"
                        >
                          APRENDE MÁS (250 ml)
                        </Link>
                        &nbsp;
                        &nbsp;
                        <Link
                          to="/es/producto/aceite-de-comino-negro-ecologico-1"
                          className="button is-text is-small btn2"
                        >
                          APRENDE MÁS (100 ml)
                        </Link>
                    </div>
                </div>
                <div id="2" className="columns __prod">
                    <div className="column">
                        <p className="title is-4">Aceite de comino negro en cápsulas</p>
                        <figure className="image is-mobile">
                            <img src={product2} alt="ACEITE DE COMINO NEGRO EN CÁPSULAS 'HALAL'" title="ACEITE DE COMINO NEGRO EN CÁPSULAS 'HALAL'"/>
                        </figure>
                        <p className="content">Las cápsulas de comino negro de MOONAMAR están hechas en su exterior de un envoltorio de gelatina con certificado  “halal” y  en su interior, de aceite de comino negro virgen de semillas egipcias. En tarros de 90 o de 250 cápsulas.</p>
                        <Link
                          to="/es/producto/aceite-de-comino-negro-en-capsulas"
                          className="button is-text is-small btn2"
                        >
                          APRENDE MÁS (90 Cápsulas)
                        </Link>
                        &nbsp;
                        &nbsp;
                        <Link
                          to="/es/producto/aceite-de-comino-negro-en-capsulas-1"
                          className="button is-text is-small btn2"
                        >
                          APRENDE MÁS (250 Cápsulas)
                        </Link>
                    </div>
                    <div className="column is-4">
                        <figure className="image is-desktop">
                            <img src={product2} alt="ACEITE DE COMINO NEGRO EN CÁPSULAS 'HALAL'" title="ACEITE DE COMINO NEGRO EN CÁPSULAS 'HALAL'"/>
                        </figure>
                    </div>
                </div>
                <div id="3" className="columns __prod">
                    <div className="column is-4">
                        <figure className="image is-desktop">
                            <img src={product3} alt="CHAMPÚ CON ACEITE DE ARGÁN Y COMINO NEGRO" title="CHAMPÚ CON ACEITE DE ARGÁN Y COMINO NEGRO"/>
                        </figure>
                    </div>
                    <div className="column">
                        <p className="title is-4">Champú de argán y comino negro</p>
                        <figure className="image is-mobile">
                            <img src={product3} alt="CHAMPÚ CON ACEITE DE ARGÁN Y COMINO NEGRO" title="CHAMPÚ CON ACEITE DE ARGÁN Y COMINO NEGRO"/>
                        </figure>
                        <p className="content">El champú Moonamar de argán y comino negro es ideal para un cuidado del cabello poco agresivo y para personas con el cuero cabelludo sensible.</p>
                        <Link
                          to="/es/producto/champu-de-argan-y-comino-negro"
                          className="button is-text is-small btn2"
                        >
                          APRENDE MÁS
                        </Link>
                    </div>
                </div>
                <div id="4" className="columns __prod">
                    <div className="column">
                        <p className="title is-4">Bálsamo para la piel con comino negro, argán, jojoba, aguacate y manteca de karité</p>
                        <figure className="image is-mobile">
                            <img src={product4} alt="BÁLSAMO PARA PIEL CON ARGÁN Y COMINO NEGRO" title="BÁLSAMO PARA PIEL CON ARGÁN Y COMINO NEGRO"/>
                        </figure>
                        <p className="content">El bálsamo para piel de Moonamar reúne los elementos esenciales para el cuidado de pieles muy secas y/o sensibles.</p>
                        <Link
                          to="/es/producto/balsamo-para-la-piel-con-comino-negro-argan-jojoba-aguacate-y-manteca-de-karite"
                          className="button is-text is-small btn2"
                        >
                          APRENDE MÁS
                        </Link>
                    </div>
                    <div className="column is-4">
                        <figure className="image is-desktop">
                            <img src={product4} alt="BÁLSAMO PARA PIEL CON ARGÁN Y COMINO NEGRO" title="BÁLSAMO PARA PIEL CON ARGÁN Y COMINO NEGRO"/>
                        </figure>
                    </div>
                </div>
                <div id="5" className="columns __prod">
                    <div className="column is-4">
                        <figure className="image is-desktop">
                            <img src={product5} alt="JABÓN DE MANTECA DE KARITÉ Y COMINO NEGRO" title="JABÓN DE MANTECA DE KARITÉ Y COMINO NEGRO"/>
                        </figure>
                    </div>
                    <div className="column">
                        <p className="title is-4">Jabón de manteca de karité con aceite de comino</p>
                        <figure className="image is-mobile">
                            <img src={product5} alt="JABÓN DE MANTECA DE KARITÉ Y COMINO NEGRO" title="JABÓN DE MANTECA DE KARITÉ Y COMINO NEGRO"/>
                        </figure>
                        <p className="content">Jabón de kariteé de Moonamar ofrecea una limpieza suave y agradable a la piel. Especialmente recomendado para personas con alergias cutáneas.</p>
                        <Link
                          to="/es/producto/jabon-de-manteca-de-karite-con-aceite-de-comino"
                          className="button is-text is-small btn2"
                        >
                          APRENDE MÁS
                        </Link>
                    </div>
                </div>
                <div id="6" className="columns __prod">
                    <div className="column">
                        <p className="title is-4">Caramelos de comino negro, curcuma, menta y limón</p>
                        <figure className="image is-mobile">
                            <img src={product6} alt="CARAMELOS CON COMINO NEGRO, CÚRCUMA Y MENTA" title="CARAMELOS CON COMINO NEGRO, CÚRCUMA Y MENTA"/>
                        </figure>
                        <p className="content">Con intenso sabor y buenos para la garganta y las vías respiratorias.</p>
                        <Link
                          to="/es/producto/caramelos-de-comino-negro-curcuma-menta-y-limon"
                          className="button is-text is-small btn2"
                        >
                          APRENDE MÁS
                        </Link>
                    </div>
                    <div className="column is-4">
                        <figure className="image is-desktop">
                            <img src={product6} alt="CARAMELOS CON COMINO NEGRO, CÚRCUMA Y MENTA" title="CARAMELOS CON COMINO NEGRO, CÚRCUMA Y MENTA"/>
                        </figure>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Produkte
